import { useIsAuthenticated } from "@azure/msal-react";
import { useRoutes } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const isAuthenticated = useIsAuthenticated();
  const routes = isAuthenticated ? protectedRoutes : [];
  const element = useRoutes([...publicRoutes, ...routes]);

  return <>{element}</>;
};
