import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { ADGroupEnum, AD_GROUPS, GROUPS_ROLE_MAPPINGS } from '@/config';
export class AuthorizationClient {
  static instance: AuthorizationClient;
  private UserRoles: string[];

  private constructor() {
    this.UserRoles = this.GetLoggedInUserRoles();
  }

  public static GetInstance(): AuthorizationClient {
    if (!AuthorizationClient.instance) {
      AuthorizationClient.instance = new AuthorizationClient();
    }
    return AuthorizationClient.instance;
  }

  public HasRoles = (requiredRoles: string[]) => {
    if (!requiredRoles?.length) {
      return true;
    }
    const hasRoles = requiredRoles.every((r) => this.UserRoles.includes(r));
    return hasRoles;
  };

  private GetLoggedInUserRoles = () => {
    let userRoles: string[] = [];

    const isAuthenticated = useIsAuthenticated();
    if (!isAuthenticated) {
      return userRoles;
    }

    const instance = useMsal();
    const groups = (instance.accounts[0].idTokenClaims as any).groups as string[];
    const matchingGroups: ADGroupEnum[] = [];

    groups.forEach((group) => {
      const match = AD_GROUPS[group];
      if (match) {
        matchingGroups.push(match);
      }
    });

    matchingGroups.forEach((group) => {
      const roles = GROUPS_ROLE_MAPPINGS[group];
      if (roles) {
        userRoles = userRoles.concat(roles);
      }
    });
    return userRoles;
  };
}
