import { AxiosError } from 'axios';
import { QueryClient, UseQueryOptions, DefaultOptions, UseMutationOptions } from 'react-query';

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type QueryConfig<TQueryFnData, TError = unknown, TData = TQueryFnData> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, string[]>,
  'queryKey' | 'queryFn'
>;

export type MutationConfig<TData, TRequest> = UseMutationOptions<TData, AxiosError, TRequest>;
