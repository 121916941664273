import { Box, BoxProps } from '@chakra-ui/react'
import Lottie from 'react-lottie-player';

import loaderJson from '@/assets/loading.json';

export const Loading = (props: BoxProps) => {
  return (
    <Box w="100px" {...props}>
      <Lottie loop animationData={loaderJson} play />
    </Box>
  );
};
