import { Configuration } from '@azure/msal-browser';

export const AI_INSTRUMENTATION_KEY = process.env.REACT_APP_AI_INSTRUMENTATION_KEY as string;

export const COUPONS_API_URL = process.env.REACT_APP_COUPONS_API_URL as string;
export const CREDIT_API_URL = process.env.REACT_APP_CREDIT_API_URL as string;
export const SHOPPING_PROFILE_API_URL = process.env.REACT_APP_SHOPPING_PROFILE_API_URL as string;
export const FABRICATOR_API_URL = process.env.REACT_APP_FABRICATOR_API_URL as string;

export const PORTAL_AUTH_SCOPES = process.env.REACT_APP_PORTAL_AUTH_SCOPES?.split(';') as string[];
export const PORTAL_INIT_AUTH_SCOPES = process.env.REACT_APP_PORTAL_INIT_AUTH_SCOPES?.split(';') as string[];

export const AUTH_CONFIG: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}/`,
    redirectUri: window.location.origin,
  },
};

export enum ADGroupEnum {
  Unknown,
  developers,
  ReadOnlySandbox,
  ReadOnlyProd,
  CustomerExperience,
  CustomerExperienceLeads,
  Collections,
  FinOps,
  ReadWriteProd,
  ReadWriteSandbox,
  Marketing,
  ProductTeam,
}

export const AD_GROUPS: Record<string, ADGroupEnum> = {
  'ae0c57f8-8110-4c22-9965-2328228df36f': ADGroupEnum.developers,
  'd890cacb-7767-4623-814e-a5e4ba8bcd96': ADGroupEnum.ReadOnlySandbox,
  '8456d2ba-cb24-4c5f-9a17-ed7cdbeab7b1': ADGroupEnum.ReadOnlyProd,
  '914fea55-3148-43b5-973e-6b6c596de3c3': ADGroupEnum.CustomerExperience,
  '6e50f6f2-fba3-45f9-ac05-cd6d09825cf8': ADGroupEnum.CustomerExperienceLeads,
  'ab7218a4-d6a7-4e04-a245-4d8e210e1757': ADGroupEnum.Collections,
  '991c810a-d409-48fe-b42f-6a12bc7a68b2': ADGroupEnum.FinOps,
  '2bf6972f-bb70-41ae-812b-9f33b76fe8dc': ADGroupEnum.ReadWriteProd,
  'ded93446-dabb-4aca-88fc-49249ac1d3a2': ADGroupEnum.ReadWriteSandbox,
  '407aeb97-e0db-4746-b52b-d759d5e67733': ADGroupEnum.Marketing,
  '9ec063de-6865-4b5f-9d49-4030cee5c650': ADGroupEnum.ProductTeam,
};

export const GROUPS_ROLE_MAPPINGS: Record<ADGroupEnum, string[]> = {
  [ADGroupEnum.Unknown]: [],
  [ADGroupEnum.developers]: process.env.REACT_APP_DEVELOPER_ROLES?.split(';') as string[],
  [ADGroupEnum.ReadOnlySandbox]: process.env.REACT_APP_READONLY_SANDBOX_ROLES?.split(';') as string[],
  [ADGroupEnum.ReadOnlyProd]: process.env.REACT_APP_READONLY_PROD_ROLES?.split(';') as string[],
  [ADGroupEnum.CustomerExperience]: process.env.REACT_APP_CX_ROLES?.split(';') as string[],
  [ADGroupEnum.CustomerExperienceLeads]: process.env.REACT_APP_CX_LEADS_ROLES?.split(';') as string[],
  [ADGroupEnum.Collections]: process.env.REACT_APP_COLLECTIONS_ROLES?.split(';') as string[],
  [ADGroupEnum.FinOps]: process.env.REACT_APP_FINOPS_ROLES?.split(';') as string[],
  [ADGroupEnum.ReadWriteProd]: process.env.REACT_APP_READWRITE_PROD_ROLES?.split(';') as string[],
  [ADGroupEnum.ReadWriteSandbox]: process.env.REACT_APP_READWRITE_SANDBOX_ROLES?.split(';') as string[],
  [ADGroupEnum.Marketing]: process.env.REACT_APP_MARKETING_ROLES?.split(';') as string[],
  [ADGroupEnum.ProductTeam]: process.env.REACT_APP_PRODUCT_ROLES?.split(';') as string[],
};
