import { MsalProvider } from "@azure/msal-react";
import { ChakraProvider, Center } from '@chakra-ui/react'
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import * as React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { theme } from './theme';

import { Loading } from '@/components/Loading';

import { reactPlugin } from '@/lib/appInsights';
import { msalClient } from '@/lib/msal-client';
import { queryClient } from '@/lib/react-query';

type AppProviderProps = {
  children: React.ReactNode;
};

const errorFallback = () => {
  const handleRefreshClick = () => window.location.reload();

  return (
    <div>
      <h2>Ooops, something went wrong :( </h2>
      <button onClick={handleRefreshClick}>Refresh</button>
    </div>
  );
};

const loading = () => {
  return (
    <Center h="100vh">
      <Loading />
    </Center>
  )
};

export const AppProvider = ({ children }: AppProviderProps) => {

  return (
    <ChakraProvider theme={theme}>
      <React.Suspense fallback={loading()}>
        <AppInsightsErrorBoundary onError={errorFallback} appInsights={reactPlugin}>
          <QueryClientProvider client={queryClient}>
            <MsalProvider instance={msalClient}>
              {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools />}
              <Router basename={process.env.PUBLIC_URL}>{children}</Router>
            </MsalProvider>
          </QueryClientProvider>
        </AppInsightsErrorBoundary>
      </React.Suspense>
    </ChakraProvider>
  );
};
