import { Box, Heading } from '@chakra-ui/react';

type ContentLayoutProps = {
  children: React.ReactNode;
  title?: string;
};

export const ContentLayout = ({ children, title }: ContentLayoutProps) => {
  return (
    <Box>
      <Heading mb={5}>{title}</Heading>
      <Box>
        {children}
      </Box>
    </Box>
  );
};
