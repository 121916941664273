import { useIsAuthenticated } from "@azure/msal-react";
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export const Landing = () => {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/app');
        } else {
            navigate('/auth/login');
        }
    }, [isAuthenticated, navigate]);

    return <div>Loading app</div>
};
