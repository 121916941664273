import { useEffect } from 'react';
import { setDefaultLocale } from '@/lib/luxon'
import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';

function App() {
  useEffect(() => setDefaultLocale(), []);

  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
