import { Spinner, Center } from '@chakra-ui/react'
import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { MainLayout } from '../components/Layout';

import { Dashboard } from '@/features/misc';
import { lazyImport } from '@/utils/lazyImport';

const { CouponsRoutes } = lazyImport(
  () => import('@/features/coupons'),
  'CouponsRoutes'
);

const { DiagnosticRoutes } = lazyImport(
  () => import('@/features/customers'),
  'DiagnosticRoutes'
);

// const { FabricatorRoutes } = lazyImport(
//   () => import('@/features/fabricator'),
//   'FabricatorRoutes'
// );

const loading = () => {
  return (
    <Center h="100vh">
      <Spinner
        thickness='4px'
        size='xl'
      />
    </Center>
  )
};

const App = () => {
  return (
    <MainLayout>
      <Suspense fallback={loading()}>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '', element: <Dashboard /> },
      { path: 'coupons/*', element: <CouponsRoutes /> },
      { path: 'customer-diagnostics/*', element: <DiagnosticRoutes /> },
      // { path: 'fabricator/*', element: <FabricatorRoutes /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
