import { extendTheme } from '@chakra-ui/react';

const colors = {
  purple: {
    50: '#7a24b6',
    100: '#411361',
  },
  gray: {
    '100': '#f7f8fa',
  },
  brand: {
    50: '#ecefff',
    100: '#cbceeb',
    200: '#a9aed6',
    300: '#888ec5',
    400: '#666db3',
    500: '#4d5499',
    600: '#3c4178',
    700: '#2a2f57',
    800: '#181c37',
    900: '#080819',
  },
};

const fonts = {
  body: "'Inter', sans-serif",
  heading: "'Inter', sans-serif",
};

export const theme = extendTheme({
  colors,
  fonts,
});
