import {
  Box,
  BoxProps,
  useDisclosure,
  Flex,
  CloseButton,
  FlexProps,
  Link,
  Icon,
  Drawer,
  DrawerContent,
  IconButton,
  Image,
  HStack,
} from '@chakra-ui/react';
import { ReactNode, ReactText } from 'react';
import { IconType } from 'react-icons';
import { FiHome, FiDollarSign, FiMenu, FiBell, FiInfo, FiDownload, FiUpload } from 'react-icons/fi';
import { Link as RouterLink } from 'react-router-dom';
import logo from '@/assets/logo.svg';
import { AuthorizationClient } from '@/lib/authorization-client';

type MainLayoutProps = {
  children: ReactNode;
};

interface LinkItemProps {
  name: string;
  icon: IconType;
  href: string;
  requiredRoles: string[];
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Home', href: '/app', icon: FiHome, requiredRoles: [] },
  {
    name: 'Customer Specific Coupons',
    href: '/app/coupons/unique-customer',
    icon: FiDollarSign,
    requiredRoles: ['ManageUniqueCustomerCoupons'],
  },
  {
    name: 'Marketing Coupons',
    href: '/app/coupons/marketing',
    icon: FiDollarSign,
    requiredRoles: ['ManageMarketingCoupons'],
  },
  {
    name: 'Customer Diagnostics',
    href: '/app/customer-diagnostics',
    icon: FiInfo,
    requiredRoles: ['CreditDetailsReader','ShoppingProfileReader'],
  },
  { 
    name: 'Export Customer', 
    href: '/app/fabricator/export', 
    icon: FiDownload,
    requiredRoles: ['ReadFabricator']
  },
  { 
    name: 'Import Customer', 
    href: '/app/fabricator/import', 
    icon: FiUpload,
    requiredRoles: ['WriteFabricator']
  }
];

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box minH="100vh">
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 80 }} p="4">
        {children}
      </Box>
    </Box>
  );
};

interface SidebarContentProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarContentProps) => {

  const authClient = AuthorizationClient.GetInstance();

  return (
    <Box
      transition="3s ease"
      bg="white"
      borderRight="1px"
      borderRightColor="gray.200"
      w={{ base: 'full', md: 80 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image boxSize="100px" src={logo} alt="Zip" />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map(
        (link) => authClient.HasRoles(link.requiredRoles) && (
            <NavItem key={link.name} icon={link.icon} href={link.href}>
              {link.name}
            </NavItem>
          )
      )}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  href: string;
  children: ReactText;
}
const NavItem = ({ icon, href, children, ...rest }: NavItemProps) => {
  return (
    <Link as={RouterLink} to={href} style={{ textDecoration: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg="gray.100"
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Image display={{ base: 'flex', md: 'none' }} boxSize="100px" src={logo} alt="Zip" />

      <MainHeader />
    </Flex>
  );
};

const MainHeader = () => {
  return (
    <HStack spacing={{ base: '0', md: '6' }}>
      <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} disabled />
      {/* TODO: Add logged user avatar and user preferences menu */}
    </HStack>
  );
};
